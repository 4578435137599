<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Gecom</h1>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="modal-cadastro-form pa-3 pb-1">
              <v-row>
                <v-col dense cols="4" sm="4" class="pb-0">
                  <v-select
                    v-model="typeGecom"
                    :items="listTypeGecomFilter"
                    label="Tipo Gecom"
                    dense
                  />
                </v-col>

                <v-col dense cols="8" class="pa-3 pt-0 pb-1 pb-0" v-if="typeGecom">
                  <v-row>
                    <v-col dense cols="6" sm="6" class="pb-0">
                      <autoCompleteEntities :entityProps.sync="entityStart" :typesEntities="[typeGecom]" :inativos="inativos" :label="'Entidade Inicial'" />
                    </v-col>
                    <v-col dense cols="6" sm="6" class="pb-0">
                      <autoCompleteEntities :entityProps.sync="entityEnd" :typesEntities="[typeGecom]" :inativos="inativos" :label="'Entidade Final'" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="align-end">
                <v-col cols="6" class="pa-3 pt-0 pb-0">
                  <rangeDate
                    :dtFinalProps.sync="dtFinal"
                    :dtInicialProps.sync="dtInicial"
                    :hasMonthOrDateProps.sync="hasMonthOrDate"
                    :inativosProps.sync="inativos"
                    :isDisplayMonthOrDateProps="true"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer pt-0">
              <v-btn
                type="submit"
                :color="variables.colorPrimary"
                @click.prevent="submit"
                class="br-btn"
                :loading="loading">Processar</v-btn>

              <!-- <v-btn
                v-if="transmissoes.results.length"
                :color="variables.colorSecondary"
                @click.prevent="handleDownload"
                class="br-btn br-btn-cancelar"
                >Baixar</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table box-table-gecom">
        <template v-if="!typeGecom">
          <TabelaRelatorioGecom
            :dataTable="transmissoes.results || []"
            :totaisGerais="transmissoes.totais || {}"
            :loadingTable="loadingTable"
            :typeGecom="typeGecom"
            :typesNotHeader="['dt_ultimo_contato']"
            @openProposta="openModalHistorioVendas"
          />
        </template>

        <template v-else-if="typeGecom === 4">
          <TabelaRelatorioGecomPromotor
            :dataTable="transmissoes.results || []"
            :totaisGerais="transmissoes.totais || {}"
            :loadingTable="loadingTable"
            :typeGecom="typeGecom"
            @openProposta="openModalHistorioVendas"
          />
        </template>

        <template v-else>
          <template v-for="(item, idx) in transmissoes.results">
            <template v-if="typeGecom === 3">
              <TabelaRelatorioGecomPromotor
                :dataTable="item.results || []"
                :totaisGerais="item.totaisGerais || {}"
                :supervisor="{ entity_cod: item.vo_entity_cod, entity_name: item.vo_entity_name }"
                :promotor="{ entity_cod: item.entity_cod, entity_name: item.entity_name }"
                :loadingTable="loadingTable"
                :typeGecom="typeGecom"
                :key="idx"

                @openProposta="openModalHistorioVendas"
                class="mb-5"
              />
            </template>


            <template v-else>
              <TabelaRelatorioGecom
                :dataTable="item.results || []"
                :totaisGerais="item.totaisGerais || {}"
                :supervisor="{ entity_cod: item.entity_cod, entity_name: item.entity_name }"
                :loadingTable="loadingTable"
                :typeGecom="typeGecom"
                :typesNotHeader="['dt_ultimo_contato']"
                :key="idx"
                class="mb-5"
              />
            </template>
          </template>
        </template>
      </div>

      <DadosEstatistica :dateInit="dtInicial" :dateEnd="dtFinal" :count="count" />
    </v-container>

    <!-- <modalTransmissoes />
    <modalJogosWinners />
    <modalCaixa />
    <modalPgto /> -->
    <ModalHistoricoVendas />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { parseTime } from '@/core/service/utils'
import moment from 'moment'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'relatoriosGecomResumido'

export default {
  name: 'Cadastro',
  components: {
    // BrToolBar: () => import('./components/toolbar'),
    autoCompleteEntities: () => import('../../components/autoCompleteEntities/gecom'),
    rangeDate: () => import('../../components/rangeDate'),
    // modalTransmissoes: () => import('./components/modalTransmissoes'),
    // modalJogosWinners: () => import('./components/modalJogosWinners'),
    // modalCaixa: () => import('./components/modalCaixa'),
    // modalPgto: () => import('./components/modalPgto'),
    ModalHistoricoVendas: () => import('./components/modalHistoricoVendas'),
    TabelaRelatorioGecom: () => import('./components/tableRelatorioGecom'),
    TabelaRelatorioGecomPromotor: () => import('./components/tableRelatorioGecomPromotor'),
    DadosEstatistica: () => import('./components/dadosEstatistica')
  },
  mixins: [listagemTables],
  data: () => ({
    loading: false,
    loadingTable: false,
    entityStart: {},
    entityEnd: {},
    entityRequest: {},
    dtFinal: '',
    dtInicial: '',
    inativos: null,
    hasMonthOrDate: 1,
    headerExcel:  ['Código da Entidade', 'Nome da Entidade', 'Caixa', 'Comissão', 'Deve Anterior', 'Deve Atual', 'Débito', 'Prêmios', 'Proposta', 'Saldo'],

    listTypeGecom: [
      { text: 'Geral', value: null },
      { text: 'Supervisor', value: 2 },
      { text: 'Promotor', value: 3 },
      { text: 'Agencia', value: 4 }
    ],
    typeGecom: null,
    dateInit: '',
    dateEnd: '',
    count: 0
  }),

  watch: {
    hasMonthOrDate (val) {
      this.setHasMonthOrDate(val)
    },

    transmissoes (val) {
      this.entityRequest = val.entity
    },

    typeGecom () {
      this.clearDados()
    }
  },

  mounted () {
    const arry = []

    console.log('user', this.user)

    this.user.userEntityPaths.forEach(item => {
      arry.push(item.split('.').length)
    })

    console.log(arry)


    this.dateEnd = this.dtFinal
    this.dateInit = this.dtInicial
  },

  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes']),
    ...mapGetters('auth', ['user']),
    listTypeGecomFilter () {
      
      return this.listTypeGecom
      // const arry = []
      // this.user.userEntityPaths.forEach(item => {
      //   arry.push(item.split('.').length)
      // })

      // const typeEntity = arry[0]
      // if (!typeEntity) return this.listTypeGecom

      // return this.listTypeGecom.filter(item =>  item.value >= typeEntity)
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'getRelatorioGecom', 'getRelatorioGecomGeral',  'setHasMonthOrDate', 'clearItens']),

    clearDados () {
      this.clearItens()
      this.entityStart = {}
      this.entityEnd = {}
    },
    
    opemDadosJogos(val) {
      Events.$emit('modal::historicoVendas::transmissoes', { 
        data: val,
        entity: this.entityRequest,
        hasMonthOrDate: this.hasMonthOrDate,
        dtFinal: this.dtFinal,
        dtInicial: this.dtInicial 
      })
    },

    opemDadosJogosWinners(val) {
      Events.$emit('modal::historicoVendas::jogosWinners', { data: val.date, entity: this.entityRequest, hasMonthOrDate: this.hasMonthOrDate })
    },

    opemDadosCaixa(val) {
      Events.$emit('modal::historicoVendas::caixa', { data: val.date, entity: this.entityRequest, hasMonthOrDate: this.hasMonthOrDate })
    },

    opemDadosPgto(val) {
      Events.$emit('modal::historicoVendas::pgto', { data: val.date, entity: this.entityRequest, hasMonthOrDate: this.hasMonthOrDate })
    },

    openModalHistorioVendas(val) {
      const data = {
        entityId: val.entity_id,
        entity_cod: val.entity_cod,
        dtStart: this.dtInicial,
        dtEnd: this.dtFinal,
        hasMonthOrDate: this.hasMonthOrDate
      }

      Events.$emit('modal::historicoVendas::historicoVendas', { ...data })
    },

    init() {},

    submit () {
      this.loading = true

      let { dtInicial, dtFinal, entityStart, entityEnd } = Object.assign({}, this)

      let isInvertEntities =  entityStart.value > entityEnd.value
      
      let entityStartId = isInvertEntities ? entityEnd.value : entityStart.value, 
        entityEndId = isInvertEntities ? entityStart.value : entityEnd.value
      
      if (this.hasMonthOrDate === 2) {
        dtInicial = moment(dtInicial, 'YYYY-MM').startOf('month').format('YYYY-MM-DD')
        dtFinal = moment(dtFinal, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
      }

      let type = 0
      switch (this.typeGecom) {
        case null :
          type = 2
          break
        case 2 :
          type = 3
          break
        case 3 :
        case 4 :
          type = 4
      }

      this.getRelatorioGecomGeral({ dtInicial, dtFinal, entityStartId, entityEndId, type, agencia: this.typeGecom === 4 ? true : undefined }).then(() => {
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
      .finally(() => {
        this.dateEnd = dtFinal
        this.dateInit = dtInicial
        this.count++
      })

    },

    handleDownload() {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const header = this.headerExcel
        const filterVal = ['entity_cod', 'entity_name', 'caixa', 'comissao', 'deve_ant', 'deve_atual', 'pagto', 'premio', 'proposta', 'saldo']
        const data = this.formatJson(filterVal)
        excel.export_json_to_excel({
          header,
          data,
          filename: 'gecom',
          autoWidth: true
        })
        this.downloadLoading = false
      })
    },
    
    formatJson(filterVal) {
      let data = []
      switch (this.typeGecom) {
        case (null) :
          data = this.transmissoes.results
          break

        case (2) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`
              data.push(promotor)
            })
          })

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break

        case (3) :
        case (4) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`

              promotor.agencias.forEach(agencia => {
                agencia.supervisor = promotor.supervisor
                agencia.promotor = `${promotor.entity_cod} - ${promotor.entity_name}`
                data.push(agencia)
              })
            })
          })

          filterVal.unshift('promotor')
          this.headerExcel.unshift('Promotor')

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break
      }

      return data.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::pgto')
    Events.$off('modal::historicoVendas::caixa')
    Events.$off('modal::historicoVendas::jogosWinners')
    Events.$off('modal::historicoVendas::transmissoes')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-gecom {
      .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
        height: auto;
        padding: 5px;
        font-size: 11px;
      }
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
